import React from "react";

const copyYear = new Date().getFullYear();

const PageFooter = () => {
  return [<footer className="page-footer opta">{`©${copyYear} Opta Information Intelligence`}</footer>,
    <footer className="page-footer verisk">{`©${copyYear} Verisk`}</footer>
  ];
};

export default PageFooter;
