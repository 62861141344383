import React, { Suspense, useEffect, useState } from "react";
import { useGlobal } from "reactn";
import Header from "./avm/Header";
import Spinner from "./avm/Spinner";
import MainView from "./avm/MainView";
import PageFooter from "./avm/PageFooter";
import { restoreSession } from "../lib/session";
import config from "../config";
import getLogger from "../lib/debug-logger";
import { setGlobalValue } from "../lib/global-util";
import { loadDataSchema } from "../lib/oss-schema";
import { getBodyClassSwappers } from "../lib/webapi";
import { propertyInfoExists, restorePropertyInfo } from "../lib/local-data";

import "../styles/main.scss";

const log = getLogger("Avm", 1);

const [addBodyClass, restoreBodyClass] = getBodyClassSwappers("body-styled");

setGlobalValue("PAGE_NAME", "main");

if (!propertyInfoExists()) {
  addBodyClass();
}

const Avm = () => {
  const [addressLine] = useGlobal("ADDRESS_LINE"); // search address as street address string
  const [addressLegalLine] = useGlobal("ADDRESS_LEGAL_LINE"); // search address as object
  const [username] = useGlobal("USERNAME");

  const [isSearchView, setIsSearchView] = useState(false);

  useEffect(() => {
    log("Configuration", config); //--------------- log
    if (!!username) return;
    restoreSession();
    loadDataSchema(); // preload OSS schema
    // restore property data if cached locally
    restorePropertyInfo(); // only if exists
  }, [username]);

  // change body class for property view instead ofn search
  useEffect(() => {
    const test = !addressLine && !addressLegalLine;
    if (!test) restoreBodyClass(); // remove background, change layout
    setIsSearchView(test);
  }, [addressLine, addressLegalLine, setIsSearchView]);

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Spinner />
      <Header propertyView={!isSearchView} loggedIn={!!username} />
      <MainView />
      <PageFooter />
    </Suspense>
  );
};

export default Avm;
