// Site navigation, language selector and sign out

import React from "react";
import cx from "classnames";
import TopNav from "./TopNav";
import NewSearchButton from "./NewSearchButton";

import "../../styles/avm/header.scss";

const Header = (props) => {
  const { propertyView = false, loggedIn = true } = props;

  return (
    <React.Fragment>
      {!loggedIn && <div className="iclarify">{"iClarify Financial Services"}</div>}
      <div className={cx("top-header", { extended: !!propertyView })}>
        <div className="company-top-logo"></div>
        <TopNav />

        {!!propertyView && (
          <div className="second-line text-right">
            <NewSearchButton />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
